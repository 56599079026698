@import "~@trv-tds/core/dist/sass/tokens/_tokens.scss";
.display-block {
  display: block;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: lightslategray;
  opacity: 0.9;
}

.modal-main {
  position:fixed;
  background: white;
  width: 35%;
  min-height: 25%;
  min-width: 18.75rem;
  min-height: 25%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 15px;
}

.modal-main .button-container {
    width: 108px;
    display: inline-block;
}

.modal-session-main {
    position:fixed;
    background: white;
    width: 35%;
    // min-width: 35%;
    // changing this as it's affecting the time out modal
    min-width: 300px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: 15px;
  }
  .widget-show-more-section{
    padding-top : 1.125rem;
  }
  
  .timer-background-color {
    background: #dadbdb;
  }