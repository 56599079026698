@import "~@trv-tds/core/dist/sass/tokens/_tokens.scss";
/* To be removed when TDS adds the classes */
.hero {
  margin-bottom: 1.2rem;
  min-height: 12rem;
  text-align: center;
  width: 100%;
  background-color: #f6f6f6;
  padding: 2.4rem 1.2rem;
}

.hero h1 {
  margin-top: 0;
}

@media (min-width: 35.9375em) {
  .hero {
    padding: 3.6rem 1.2rem;
  }

  .hero a {
    width: auto;
  }
}

@media (min-width: 50em) {
  .hero {
    padding: 5.4rem 1.2rem;
  }
}

.tds-centered {
  text-align: center;
}

.rw-bannerInfo {
  background: $c-accent-blueberry;
  color: #f6f6f6;
}

.rw-bannerInfo {
  background: $c-accent-blueberry;
  color: #f6f6f6;
}
.bg-color-white {
  background: #fff;
}
.rw-container {
  padding-top: 10px;
  background: $c-neutral-light5;
}
.rw-disclaimer {
  padding-top: 50px;
}

$s-container-max-width : 1024px !important;

.rw-contrast-app,
main.tds-body {
  background-color: $c-neutral-light5;
}

.rw-contrast-block {
  background-color: $c-accent-blueberry;
  color: white;
  display: flex;
  flex-direction: column;
}

.rw-block_right {
  background-color: $c-base;
  display: flex;
  justify-content: center;
  align-items: center;
}

section:focus,
section:active {
  outline-color: $c-neutral-light5 !important;
}

section#content-link {
  outline: none;
}

@media (min-width: 1024px) {
  .rw-section {
    padding: 0 8rem 0 8rem;
  }
}

@media (max-width: 1023px) {
  .rw-section {
    padding: 0 2rem 0 2rem;
  }
}