@import "~@trv-tds/core/dist/sass/tokens/_tokens.scss";
.sa-card {
    height: 100% !important;
}
@media (max-width: 450px) {
    .sa-h1 {
        font-size: 2rem !important;
        line-height: 2rem !important;
    }
}
.card {
    padding: 20px;
}

.asmt-link {
    display: inline-block;
}

