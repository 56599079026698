// @import "~@trv-ebus/trv-styles/scss/functions";
// @import "~@trv-ebus/trv-styles/scss/variables";

$error-page-width: 700px;
$mobile-page-width: 736px;


.rw-error-contrast-app {
  background-color: white;
}


.error-page-content {
    max-width: $error-page-width;
    margin: 0 auto;
    font-size: 16px;
    .error-header {
        font-family: "Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Tahoma,sans-serif;
        text-align: left;
        font-size: 1.75rem;
        color: #cc2d30;
    }

.button-container {
  margin: 0 auto;
  width: 300px;
  .btn-close-err {
    color: white;
  }
}
#btnCriticalErrorClose {
  display: block;
  width: 100%;
}
    @media (min-width: 0px) and (max-width: $mobile-page-width) {
        .mobile-card .card {
            border: none !important;
            padding: 0px 22px !important;
        }
        .error-header {
        margin: .75em 20px;
        }

    }
}


