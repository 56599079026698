@import "./pressure-test-main.css";
@import "~@trv-tds/core/dist/sass/tds";
@import "./topicsanddemographics.scss";
@import "./customTimeout.scss";
@import "./home.scss";

.question-card-background-override {
  background: white;
}

.error-card-override {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem;
  border-top-color: rgba(0, 0, 0, 0.125);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.125);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgba(0, 0, 0, 0.125);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  font-size: 16px;
}

.card-block {
  flex: 1 1 auto;
  padding: 1.125rem;
}

.question-card-example {
  font-family: BattersonSans, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.9rem; //1.05rem
  line-height: 1.25;
  margin: 0 0 0.3rem 0;
}

.rc-progress-line-path {
  max-height: 10px;
}

@media screen and (max-width: 400px) {
  .rw-mob-pt-s {
    padding-top: 12px;
  }
}

.display-block {
  display: block;
}

html {
  font-size: 95%;
}

// fix for feedback link hover/focus BG color.

a.QSILink:hover,a.QSILink:focus{
  background-color:transparent
}
a.QSILink{
  text-decoration: none !important;
}